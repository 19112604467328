import * as RadixSwitch from '@radix-ui/react-switch';
import React from 'react';

import styles from './Switch.module.css';

export default function SwitchSmall (props: {
  rootProps?: RadixSwitch.SwitchProps & React.RefAttributes<HTMLButtonElement>;
  thumbProps?: RadixSwitch.SwitchThumbProps & React.RefAttributes<HTMLSpanElement>;
}) {
  const rootClasses = [styles.root, props.rootProps?.className].filter(Boolean).join(' ');
  const thumbClasses = [styles.thumb, props.rootProps?.className].filter(Boolean).join(' ');

  return <RadixSwitch.Root
    {...props.rootProps ?? {}}
    className={rootClasses}>
    <RadixSwitch.Thumb
      {...props.thumbProps ?? {}}
      className={thumbClasses} />
  </RadixSwitch.Root>;
}
